.Content {
    margin-top: 44px;
    text-align: -webkit-center;
}

.Tabs {
    position: absolute;
    right: 30px;
    display: inline-block !important;
}

.IconTabs {
    position: absolute;
    right: 30px;
}

.Container {
    min-height: 58px;
    width: 95%;
    /*margin-top: 56px;*/
    position: fixed;
    background-color: #ffffff;
    z-index: 300;
    left: 30px;
    border-bottom: 2px solid #768828;
    top: 36px;
    box-shadow: 0 8px 6px -6px #768828;
}

.ContainerSmall {
    min-height: 20px;
    width: 95%;
    /*margin-top: 56px;*/
    position: fixed;
    background-color: #ffffff;
    z-index: 300;
    left: 30px;
    border-bottom: 2px solid #c1ca80;
    top: 28px
}

.TopContainer {
    min-height: 38px;
    width: 95%;
    /*margin-top: 56px;*/
    position: fixed;
    background-color: #ffffff;
    z-index: 300;
    left: 30px;
    top: 0px;
}

.Divider {
    min-width: 90px;
}

.Divider:after {
    content: '';
    position: absolute;
    border-left: 2px solid #c1ca80;
    height: 40%;
    right: 0;
}

.Inline {
    display: inline-block;
}
