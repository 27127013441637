.BuyDevice {
    color: black;
    width: 60%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
}

.Title {
    margin-top: 60px;
    color: #c1ca80;
}
