.Magazzino {
    /*color: black;*/
    width: 90%;
    text-align: -webkit-center;
    padding: 10px;
    box-sizing: border-box;
}

.Title {
    margin-top: 60px;
    color: #c1ca80;
}

.MuiDataGrid-root .MuiDataGrid-colCellTitleContainer {
    flex: 1;
    display: inline-flex;
    white-space: nowrap;
    text-overflow: ellipsis;
}
